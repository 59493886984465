/* src/MessageList.css */
.message-list {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  border-bottom: 1px solid #ccc;
  background-color: #f9f9f9;

  
}

.message {
  padding: 10px;
  border-bottom: 1px solid #eee;
text-align: left;
}

.messageBot {
  padding: 10px;
  border-bottom: 1px solid #eee;
  text-align: left;
 
}

.Bot{
  display: block;
    border-radius:10%;
  width:auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;


  color: #45474B;
  background-color:aquamarine;
}

.User{
  display: block;
  
    border-radius:10%;
  width:auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;

}

.Input{
  color: #45474B;
  background-color: aqua;

}

.Fix{
  color: #45474B;
  width:auto;

}
