/* src/MessageInput.css */
.message-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
    background-color: #fff;
  }
  
  .message-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .message-input button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .message-input button:hover {
    background-color: #0056b3;
  }
  