/* src/ChatApp.css */
.chat-app {
  display: flex;
  flex-direction: column;
  height: max-content;
  /* height: 100vh; */
  /* width: 400px; */
  /* margin: auto; */
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
